<template>
  <div class="job-landing-page">
      <div v-loading="isPending" class="job-landing-page-wrapper">
          <div class="details">
            <h2>שם המשרה</h2>
            <p>{{job_data.job_header}}</p>
            <h2>תפקיד</h2>
            <p>{{job_data.role}}</p>
            <h2>פרטי המשרה</h2>
            <p>{{job_data.job_details}}</p>
            <h2>דרישות התפקיד</h2>
            <div class="requirements">
                <template v-for="(req,index) in job_data.requirements" :key="index">
                    <p class="requirement">{{req.requirement}}</p>
                </template>
            </div>
            <div @click="go_back" class="back-btn">
                 <i class="material-icons">arrow_back</i>
            </div>
          </div>

          <div class="apply-form">
            <div  class="apply-form">
                <div class="apply-form-field">
                    <p>שם פרטי:</p>
                        <el-input v-model="values.first_name" name="fname" placeholder="שם פרטי" />
                </div>
                <div class="apply-form-field">
                    <p>שם משפחה:</p>
                        <el-input v-model="values.last_name" name="lname" placeholder="שם משפחה" />
                </div>
                <div class="apply-form-field">
                    <p>טלפון נייד:</p>
                        <el-input v-model="values.phone" type="tel" name="phone" placeholder="טלפון נייד" />
                </div>
                <div class="apply-form-field">
                    <p>כתובת אימייל:</p>
                        <el-input type="email" name="email" v-model="values.email" placeholder="כתובת אימייל" />
                </div>
                <div class="apply-form-field">
                    <p>קצת על עצמך (לא חובה)</p>
                        <el-input
                                v-model="values.describe"
                                :rows="3"
                                type="textarea"
                                placeholder="קצת על עצמי..."
                        />
                </div>
                <div class="apply-form-field">
                    <h3>נא לסמן על אלו דרישות את/ה עונה</h3>
                    <template v-for="req in requirements_marked" :key="req">
                        <p :class="{reqMarked:req.marked,reqNotMarked:!req.marked}" style="font-size:18px;" @click="req.marked = !req.marked ">
                            <i v-if="req.marked" style="color:green;" class="material-icons">done</i>
                            {{req.req}}
                        </p>
                     </template>   

                </div>
                <div class="apply-form-field">
                    <p>העלאת קובץ קו"ח</p>
                    <input @change="handleChange" type="file">
                </div>
                <div class="apply-form-field">
                    <el-button @click="handle_submit" style="background:#7367f0; width:100%; height:70px; color:#fff; font-size:20px;">שליחה</el-button>
                </div>
            </div>
          </div>
      </div>
  </div>

   <!-- just for email -->
    <div ref="email_to_user" class="email-to-user" id="email-to-user" v-show="false">
        <p style="font-size:20px;">שלום <strong>{{ values.first_name }} {{values.last_name}}</strong>,</p>
        <p style="font-size:20px;">תודה לך על הגשת מועמדותך למשרה: <strong>{{job_data.job_header}}</strong> בחברתנו!</p>
        <p style="font-weight: bold; font-size:20px;">
            אנו נעיין בקורות החיים שלך ונחזור אלייך בהקדם האפשרי עם תשובה - עד 14 ימי עסקים
        </p>
        <p style="font-size:20px;">
            בהצלחה,
        </p>
        <p style="font-size:20px;">
            מחלקת משאבי אנוש.
        </p>
       <img v-if="job_data.job_company=='שיווק'" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/logos%2Frosman-market_375x375.png?alt=media&token=b055241e-d401-4ae2-9c14-178553610dc2"/>
       <img v-if="job_data.job_company=='לוגיסטיקה'" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/logos%2Froman-logistic_375x375.png?alt=media&token=4efd29d7-2d7d-4fae-99b3-f467a7c4d693"/>
       <img v-if="job_data.job_company=='דיוטי פארם'" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/logos%2Fduty-pharm_375x375.png?alt=media&token=1f925d2c-95b0-4def-836a-d984241a25c1"/>

    </div>
    <!-- just for mail -->
    <div ref="form_email" class="form-email" id="form-email" v-show="false">
      <table
        style="width:80%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
      >
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שם המשרה
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{job_data.job_header}}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שם המועמד:
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.first_name}} {{values.last_name}}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            טלפון נייד
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.phone}}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            דוא"ל
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.email}}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            המועמד כתב/ה על עצמו
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.describe?values.describe:'לא כתב/ה כלום'}}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px; color:green">
            עונה על הדרישות
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.skills.join(", ")}}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px; color:red;">
            לא עונה על הדרישות
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.not_skills.join(", ")}}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            קורות חיים
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            <a :href="values.cv.url" target="_blank">לצפייה לחצי כאן</a>
          </td>
        </tr>
      </table>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {projectFirestore,projectFunctions} from '../../firebase/config'
import {alert,slide_pop_error} from '../../Methods/Msgs'
import use_jobs_storage from '../../Methods/use_jobs_storage'
import { computed, onMounted } from '@vue/runtime-core'
import router from '../../router'
export default {
    props:['jobId'],
    setup(props){
        const {url,filePath,uploadCv} = use_jobs_storage()
        const isPending = ref(false)
        const error_msg = ref('')
        const file = ref(null)
        const email_to_user = ref()
        const form_email = ref()

        const go_back = ()=>{
            router.go(-1)
        }
        const requirements_marked = ref([])

        const job_data = ref({
            createdAt:'',
            job_company:'',
            job_details:'',
            job_header:'',
            requirements:[],
            role:'',
            image:{
                file_path:'',
                url:'',
            }
        })

        const values = ref({
            first_name:'',
            last_name:'',
            phone:'',
            email:'',
            describe:'',
            skills:[],
            not_skills:[],
            cv:{
                url:'',
                file_path:''
            }
        })
        
        const validation = ()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'first_name':
                        if(!value){
                            error_msg.value = 'עליך למלא שם פרטי'
                            return false
                        }
                        break;
                    case 'last_name':
                        if(!value){
                            error_msg.value = 'עליך למלא שם משפחה'
                            return false
                        }
                        break;
                    case 'phone':
                        if(value.length!=10){
                            error_msg.value = 'עליך למלא מס טלפון תקין'
                            return false
                        }
                        break;
                    case 'email':
                        if(!value.includes('@')){
                            error_msg.value = 'עליך למלא כתובת אמייל תקינה'
                            return false
                        }
                        break;
                    case 'cv':
                        if(!file.value){
                            error_msg.value = 'עליך להעלות קובץ קורות חיים'
                            return false
                        }
                        break;
                }
            }
            return true
        }

        const req_validation = ()=>{
            const index = requirements_marked.value.findIndex(req=>req.marked)
            if(index==-1){
                error_msg.value = 'עליך לסמן על אלו דרישות את/ה עונה'
                return false
            }
            return true
        }

        const handle_submit = async()=>{
            if(!validation()){
                slide_pop_error(error_msg.value)
            }
            else if(!req_validation()){
                slide_pop_error(error_msg.value)
            }
            else{
                isPending.value = true
                values.value.not_skills = not_skills.value
                values.value.skills = marked_skills.value
                
                uploadCv(file.value,remove_slashes(job_data.value.job_header),`${remove_slashes(values.value.first_name)} ${remove_slashes(values.value.last_name)}`)
                values.value.cv.url = url.value
                values.value.cv.file_path = filePath.value
                store_data_in_db()
                send_email_to_user(values.value.email)
                send_email_to_charge()
                isPending.value = false
                alert('success','הצלחה','תודה לך על השארת פרטים')
                .then(()=>{
                    reset()
                })
            }
        }
        const get_job_data_from_db=async()=>{
            const doc = await projectFirestore.collection('Jobs').doc(props.jobId).get()
            job_data.value = doc.data()
            delete job_data.value.applicators
        }

        const not_skills = ref(computed(()=>{
            return requirements_marked.value.filter(skill=>!skill.marked).map(skill=>skill.req)
        }))

        const marked_skills = ref(computed(()=>{
            return requirements_marked.value.filter(skill=>skill.marked).map(skill=>skill.req)
        }))
        
        const reset = ()=>{
            values.value = {
                first_name:'',
                last_name:'',
                phone:'',
                email:'',
                describe:'',
                skills:[],
                not_skills:[],
                cv:{
                    url:'',
                    file_path:''
                }
            }
            requirements_marked.value = job_data.value.requirements.map(req=>({req:req.requirement,marked:false}))
            file.value = null
        }

        const store_data_in_db=async()=>{
            await projectFunctions.httpsCallable('insert_new_applicator')({
                ...values.value,
                id:props.jobId
            })
        }
        const handleChange=async(e)=>{
            const selected = e.target.files[0];
            file.value = selected;
        }

        const send_email_to_user = async(email)=>{
            console.log('email_sent:',email);
            await projectFunctions.httpsCallable('send_email')({
              html: email_to_user.value.innerHTML,
              destinations:[email],
              from:"רוסמן דרושים",
              title:`שלום ${values.value.first_name} ${values.value.last_name}`
            })
        }
        const send_email_to_charge = async()=>{
           await projectFunctions.httpsCallable('send_email')({
              html: form_email.value.innerHTML,
              destinations:["rosmanjobs@gmail.com","rosman.mate@gmail.com","shiraz@rosman.co.il"],
              from:"מערכת דרושים",
              title:`משרה:${job_data.value.job_header} מועמד:${values.value.first_name} ${values.value.last_name}`
          })
        }

        function remove_slashes(str) {
            return str.split('/').join('')
        }

        onMounted(async()=>{
            await get_job_data_from_db()
            requirements_marked.value = job_data.value.requirements.map(req=>({req:req.requirement,marked:false}))
        })
        return{
            email_to_user,
            form_email,
            not_skills,
            marked_skills,
            handleChange,
            handle_submit,
            values,
            job_data,
            error_msg,
            isPending,
            requirements_marked,
            go_back,
            remove_slashes
        }
    }
}
</script>

<style scoped>
    .job-landing-page{
        width: 100%;
        height: 100%;
        overflow:hidden;
        overflow-y: auto;
    }
    .job-landing-page-wrapper{
        border-radius: 10px;
        padding: 15px;
        margin: 0 auto;
        width: 60%;
        min-height: 100%;
        background: #fff;
        
    }
    .details{ 
        position: relative;
        width: 100%;
        height: fit-content;
        overflow:hidden;
        overflow-y: auto;
        line-height: 1.8;
    }
  
    .details .back-btn{
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
        z-index: 5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: var(--purple);
        border-radius: 50%;
        color: #fff;
        cursor: pointer;
        user-select: none;
    }

    .details h1{
        font-weight:400;
    }
    .details p{
        white-space: pre-wrap;
        font-size: 18px;
        line-height: normal;
    }
    .details .requirements{
        width: 100%;
        height: fit-content;
        display: flex;
        flex-wrap: wrap;
    }
    .details .requirements .requirement{
        background:var(--purple);
        padding: 2px;
        border-radius: 5px;
        color: #fff;
        margin:5px 2px;
    }
    .apply-form{
        margin-top: 15px;
        position: relative;
        width: 100%;
        height: fit-content;
    }
    .apply-form-field{
        margin-top:10px;
        width: 100%;
        height: fit-content;
    }
    .apply-form-field p{
        font-size: 25px;
    }
    .apply-form-btn{
        position: absolute;
        width: 100%;
        height: fit-content;
        bottom: 0;
        left: 0;
    }
    .reqNotMarked{
        background: #D3DCEB;
        border-radius: 5px;
        padding: 2px;
        margin-top: 5px;
        cursor: pointer;
    }
    .reqMarked{
        background: #f1faee;
        border-radius: 5px;
        padding: 2px;
        margin-top: 5px;
        cursor: pointer;
    }

    @media only screen and (max-width: 600px){
        .job-landing-page-wrapper{
            width: 100%;
        }
    }

</style>